import request from '@/utils/request'

// 查询订单数量
export function findOrderStatisticCount(params) {
  return request({
    url: `/order_statistics/count`,
    method: 'get',
    params: params
  })
}

// findOrderStatisticCountTrend 查询订单数量趋势
export function findOrderStatisticCountTrend(params) {
  return request({
    url: `/order_statistics/count/trend`,
    method: 'get',
    params: params
  })
}

// findServiceOrderStatisticStatusCount 查询殡仪服务订单状态数量
export function findServiceOrderStatisticStatusCount(params) {
  return request({
    url: `/order_statistics/count/service_order/status`,
    method: 'get',
    params: params
  })
}

// findServiceOrderStatisticUnderwayStatusCount 查询进行中的殡仪服务订单数量
export function findServiceOrderStatisticUnderwayStatusCount(params) {
  return request({
    url: `/order_statistics/count/service_order/underway/status`,
    method: 'get',
    params: params
  })
}

// findSubscribeOrderStatisticStatusCount 查询附加产品订单状态数量
export function findSubscribeOrderStatisticStatusCount(params) {
  return request({
    url: `/order_statistics/count/subscribe_order/status`,
    method: 'get',
    params: params
  })
}

// 查询订单费用
export function findOrderStatisticFee(params) {
  return request({
    url: `/order_statistics/fee`,
    method: 'get',
    params: params
  })
}

// findOrderStatisticFeeTrend 查询订单费用趋势
export function findOrderStatisticFeeTrend(params) {
  return request({
    url: `/order_statistics/fee/trend`,
    method: 'get',
    params: params
  })
}

// findOrderFeeDistributionStatistics 查询订单费用分布
export function findOrderFeeDistributionStatistics(params) {
  return request({
    url: `/order_statistics/fee/distribution`,
    method: 'get',
    params: params
  })
}
