<template>
  <div>
    <a-spin :spinning="loading" style="margin-bottom: 10px">
      <div class="box">
        <div class="column">
          <div class="count-box">
            <div class="title">
              订单
              <br>
              总额
            </div>
            <div class="count-value">{{ data.total_fee? data.total_fee:0 | formatCurrency }}</div>
          </div>
        </div>

        <div class="column">
          <div class="count-box">
            <div class="title">
              殡仪服务
              <br>
              订单总额
            </div>
            <div class="count-value">{{ data.service_fee? data.service_fee:0 | formatCurrency }}</div>
          </div>
        </div>

        <div class="column">
          <div class="count-box">
            <div class="title">
              附加产品
              <br>
              订单总额
            </div>
            <div class="count-value">{{ data.subscribe_fee? data.subscribe_fee:0 | formatCurrency }}</div>
          </div>
        </div>

        <div class="column">
          <div class="count-box">
            <div class="title">
              厅房费用
              <br>
              总额
            </div>
            <div class="count-value">{{ data.hall_fee? data.hall_fee:0 | formatCurrency }}</div>
          </div>
        </div>

        <div class="column">
          <div class="count-box">
            <div class="title">
              未支付
              <br>
              订单总额
            </div>
            <div class="count-value">{{ data.un_paid_fee? data.un_paid_fee:0 | formatCurrency }}</div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { findOrderStatisticFee } from '@/api/order_statistic'
export default {
  name: 'OrderFee',
  props: {
    beginTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findOrderStatisticFee({ date_begin: this.beginTime, date_end: this.endTime }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  display: flex;
  justify-content: flex-start;

  .column {
    flex-basis: 20%;
    padding: 0 5px;

    .count-box {
      background-color: #159bd5;
      height: 80px;
      border-radius: 3px;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;

      .title {
        text-align: center;
      }

      .count-value {
        color: @orange;
        font-weight: bold;
      }
    }
  }
}

</style>
