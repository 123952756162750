<template>
  <div>
    <a-row :gutter="[16,16]">
      <a-col span="12">
        <div class="custom-card-box">
          <div class="custom-padding-bottom-10px custom-flex custom-flex-row custom-justify-between">
            <div>
              <span class="title">订单统计</span>
              <a-range-picker
                :show-time="{ format: 'HH:mm' }"
                :placeholder="['开始时间', '结束时间']"
                format="YYYY-MM-DD HH:mm"
                style="width: 340px;"
                @change="handleChange"
                :value="value"
              />
            </div>
            <span />
          </div>
          <div class="box-height">
            <order-count ref="orderCount" :begin-time="startTime" :end-time="endTime" />
            <order-count-trend ref="orderCountTrend" :begin-time="startTime" :end-time="endTime" />
            <a-row :gutter="12">
              <a-col span="12">
                <service-order-status-chart ref="serviceOrderStatusChart" :begin-time="startTime" :end-time="endTime" />
              </a-col>
              <a-col span="12">
                <subscribe-order-status-chart ref="subscribeOrderStatusChart" :begin-time="startTime" :end-time="endTime" />
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>

      <a-col span="12">
        <div class="custom-card-box">
          <div class="custom-padding-bottom-10px custom-flex custom-flex-row custom-justify-between">
            <div>
              <span class="title">订单费用统计</span>
              <a-range-picker
                :show-time="{ format: 'HH:mm' }"
                :placeholder="['开始时间', '结束时间']"
                format="YYYY-MM-DD HH:mm"
                style="width: 340px;"
                @change="handleFeeChange"
                :value="feeValue"
              />
            </div>
            <span />
          </div>
          <div class="box-height">
            <fee-count ref="feeCount" :begin-time="feeStartTime" :end-time="feeEndTime" />
            <order-fee-trend ref="orderFeeTrend" :begin-time="feeStartTime" :end-time="feeEndTime" />
            <order-fee-distribution-chart ref="orderFeeDistributionTrend" :begin-time="feeStartTime" :end-time="feeEndTime" />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import OrderCount from '@/views/fee_data/fee_statistics/OrderCount'
import OrderCountTrend from '@/views/fee_data/fee_statistics/OrderCountTrend'
import ServiceOrderStatusChart from '@/views/fee_data/fee_statistics/ServiceOrderStatusChart'
import SubscribeOrderStatusChart from '@/views/fee_data/fee_statistics/SubscribeOrderStatusChart'
import FeeCount from '@/views/fee_data/fee_statistics/FeeCount'
import OrderFeeTrend from '@/views/fee_data/fee_statistics/OrderFeeTrend'
import OrderFeeDistributionChart from '@/views/fee_data/fee_statistics/OrderFeeDistributionChart'
import { formatDateTimeMin } from '@/utils/time'
export default {
  name: 'FeeStatisticIndex',
  components: {
    SubscribeOrderStatusChart,
    ServiceOrderStatusChart,
    OrderCount,
    FeeCount,
    OrderCountTrend,
    OrderFeeTrend,
    OrderFeeDistributionChart
  },
  data() {
    return {
      timeType: 'day',
      startTime: '',
      endTime: '',
      value: [],
      feeStartTime: '',
      feeEndTime: '',
      feeValue: [],
      feeTimeType: 'day'
    }
  },
  methods: {
    handleChange(value) {
      this.value = value
      if (value !== '') {
        this.startTime = formatDateTimeMin(value[0])
        this.endTime = formatDateTimeMin(value[1])
      } else {
        this.startTime = ''
        this.endTime = ''
      }
      this.$nextTick(() => {
        this.$refs.orderCount.fetchData()
        this.$refs.orderCountTrend.fetchData()
        this.$refs.serviceOrderStatusChart.fetchData()
        this.$refs.subscribeOrderStatusChart.fetchData()
      })
    },
    handleFeeChange(value) {
      this.feeValue = value
      if (value !== '') {
        this.feeStartTime = formatDateTimeMin(value[0])
        this.feeEndTime = formatDateTimeMin(value[1])
      } else {
        this.feeStartTime = ''
        this.feeEndTime = ''
      }
      this.$nextTick(() => {
        this.$refs.feeCount.fetchData()
        this.$refs.orderFeeTrend.fetchData()
        this.$refs.orderFeeDistributionTrend.fetchData()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box-height {
  height: 800px;
}
.title {
  color: @primary-color;
  margin-right: 10px;
}
</style>
