<template>
  <div>
    <a-spin :spinning="loading">
      <a-row :gutter="12" style="margin-bottom: 10px">
        <a-col span="8">
          <div class="count-box">
            <div>订单总数</div>
            <div class="count-value">{{ data.total_count | formatBigNumber }}</div>
          </div>
        </a-col>
        <a-col span="8">
          <div class="count-box">
            <div>殡仪服务订单总数</div>
            <div class="count-value">{{ data.service_count | formatBigNumber }}</div>
          </div>
        </a-col>
        <a-col span="8">
          <div class="count-box">
            <div>附加产品订单总数</div>
            <div class="count-value">{{ data.subscribe_count | formatBigNumber }}</div>
          </div>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { findOrderStatisticCount } from '@/api/order_statistic'
export default {
  name: 'OrderCount',
  props: {
    beginTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: {},
      loading: true
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findOrderStatisticCount({ date_begin: this.beginTime, date_end: this.endTime }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-box {
  background-color: @primary-color;
  height: 80px;
  border-radius: 3px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;

  .count-value {
    color: @orange;
    font-weight: bold;
  }
}
</style>
