<template>
  <div class="custom-padding-bottom-10px">
    <a-spin
      :spinning="loading"
    >
      <div
        ref="orderTrendChart"
        style="height: 220px"
      />
    </a-spin>
  </div>
</template>

<script>
import { findOrderStatisticCountTrend } from '@/api/order_statistic'
import { formatBigNumber } from '@/utils/filter'

export default {
  name: 'OrderCountTrendChart', // 订单数量趋势折线图
  props: {
    beginTime: {
      type: String,
      required: true
    },
    endTime: {
      type: String,
      required: true
    }
  },
  data() {
    this.handleWindowResize = this.$lodash.throttle(this.handleWindowResize, 1000)
    return {
      chart: null,
      tagStatistics: [],
      loading: true
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  computed: {
    chartOption() {
      return {
        tooltip: {
          trigger: 'axis',
          position: (point, params, dom, rect, size) => {
            // size为当前窗口大小
            if ((size.viewSize[0] / 2) >= point[0]) {
              // 其中point为当前鼠标的位置
              return [point[0] + 20, '10%']
            } else {
              // 其中point为当前鼠标的位置
              return [point[0] - 200, '10%']
            }
          },
          formatter: (params) => {
            let relVal = params[0].name
            for (let i = 0; i < params.length; i++) {
              relVal += `<br/>${params[i].marker}
              <span style="width: 100px; overflow: hidden;text-overflow: ellipsis; display: inline-block; vertical-align: bottom">
              ${params[i].seriesName}
              </span>
              &nbsp;&nbsp;
              ${formatBigNumber(params[i].value)}个`
            }
            return relVal
          }
        },
        legend: { data: [], type: 'scroll' },
        grid: { x: 40, y: 50, x2: 30, y2: 30 },
        xAxis: { type: 'category', data: [] },
        yAxis: { type: 'value' },
        series: []
      }
    }
  },
  methods: {
    handleWindowResize() {
      this.chart && this.chart.resize()
    },

    // 用于设置渐变色
    setSeries(i) {
      // 从全局色中获取一个颜色
      const seriesColor = this.chart._theme.color[i]
      this.chartOption.series.push({
        type: 'line',
        data: this.$lodash.map(this.tagStatistics[i].statistics, 'count'),
        name: this.tagStatistics[i].tag_name,
        color: seriesColor,
        areaStyle: {
          color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: seriesColor
            },
            {
              offset: 0.8,
              color: 'rgba(255, 255, 255, 0)'
            }
          ])
        }
      })
    },

    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.orderTrendChart, 'echarts_custom')
      this.chartOption.legend.data = this.$lodash.map(this.tagStatistics, 'tag_name')
      this.chartOption.xAxis.data = this.$lodash.map(this.tagStatistics[0].statistics, 'name')
      this.chartOption.series = []
      for (let i = 0; i < this.tagStatistics.length; i++) {
        this.setSeries(i)
      }
      this.chart.setOption(this.chartOption, true)
    },

    fetchData() {
      this.loading = true
      findOrderStatisticCountTrend({ date_begin: this.beginTime, date_end: this.endTime }).then((res) => {
        if (res.code === 0) {
          this.tagStatistics = res.data
          this.drawChart()
        }
        this.loading = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
